import React from 'react'
import { connect } from 'react-redux'
import QRCode from 'react-qr-code'

import css from './QrcodeMagnifier.module.scss'

import { hideQrcode } from '../../../redux/actions'

const QrcodeMagnifier = (props) => {
  if (!props.ui.qrcode) return null

  return (
    <div className={css.QRCode} onClick={props.hideQrcode}>
      <span>
        <QRCode value={props.ui.qrcode} />
      </span>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { hideQrcode })(QrcodeMagnifier)
