import { adConstants } from '../constants'

const initialState = {
  list: null
}

export default function ad(state = initialState, action) {
  switch (action.type) {
    case adConstants.AD_UPDATE:
      return {
        ...state,
        list: (state.list === null
          ? [action.payload]
          : state.list.findIndex((x) => x.id === action.payload.id) === -1
          ? state.list.concat(action.payload)
          : state.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        ).sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
      }
    case adConstants.AD_REMOVE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        })
      }
    case adConstants.AD_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
