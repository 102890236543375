import { combineReducers } from 'redux'

import auth from './auth.reducer'
import user from './user.reducer'
import badge from './badge.reducer'
import activity from './activity.reducer'
import product from './product.reducer'
import ad from './ad.reducer'
import ui from './ui.reducer'
import admin from './admin.reducer'
import request from './request.reducer'
import redirect from './redirect.reducer'
import weapon from './weapon.reducer'

const rootReducer = combineReducers({
  auth,
  user,
  badge,
  activity,
  product,
  ad,
  ui,
  admin,
  request,
  redirect,
  weapon
})

export default rootReducer
