import { badgeConstants } from '../constants'

export const initBadge = () => {
  return {
    type: badgeConstants.BADGE_INIT
  }
}

export const updateBadge = (payload) => {
  return {
    type: badgeConstants.BADGE_UPDATE,
    payload
  }
}

export const removeBadge = (payload) => {
  return {
    type: badgeConstants.BADGE_REMOVE,
    payload
  }
}

export const saveBadge = (id, payload) => {
  return {
    type: badgeConstants.BADGE_SAVE,
    id,
    payload
  }
}

export const deleteBadge = (id) => {
  return {
    type: badgeConstants.BADGE_DELETE,
    id
  }
}

export const resetBadge = () => {
  return {
    type: badgeConstants.BADGE_RESET
  }
}
