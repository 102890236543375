import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { usersRef, activitiesRef, database } from '../../database'

import {
  updateUser,
  removeUser,
  saveUserProfileSuccess,
  saveUserProfileFailure,
  addNotification
} from '../actions'
import {
  query,
  where,
  onSnapshot,
  writeBatch,
  doc,
  getDocs,
  updateDoc
} from 'firebase/firestore'
import { timeHelper, uiHelper } from '../../helpers'
import { appearanceConstants, firestoreConstants } from '../../constants'

export let userSnap

export function* loadUserSaga(action) {
  try {
    const listener = eventChannel((emit) => {
      const q = query(usersRef, where('uid', '==', action.payload.uid))
      userSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => userSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id

      if (change.type === 'added') {
        yield put(updateUser({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateUser({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeUser({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: cosa fare in caso di errore
    console.log(error)
  }
}

export function* verifyUserSaga(action) {
  try {
    const q = query(usersRef, where('uid', '==', action.payload.uid))
    const querySnapshot = yield getDocs(q)

    if (querySnapshot.docs.length === 0) {
      let providerId = 'emailpassword'
      try {
        providerId = action.payload?.providerData[0]?.providerId
      } catch {}

      const user = {
        uid: action.payload.uid,
        coins: 3,
        displayName: action.payload.displayName || 'user',
        phoneNumber: action.payload.phoneNumber || '',
        email: action.payload.email || '',
        vip: false,
        cbcard: '',
        providerId
      }
      const firstBadge = {
        uid: action.payload.uid,
        date: timeHelper.currentTime(),
        tag: 'tc-join',
        type: appearanceConstants.activities.badge,
        title: 'Iscrizione a TactiClub',
        image: 'badges/tacticlub/join.png',
        points: 3
      }

      const batch = yield writeBatch(database)
      batch.set(doc(activitiesRef), firstBadge)
      batch.set(doc(usersRef), user)
      batch.commit()
    }
  } catch (error) {
    console.log(error)
  }
}

export function* saveUserProfileSaga(action) {
  try {
    const { body, id } = action.payload
    yield updateDoc(doc(database, firestoreConstants.users, id), body)

    yield put(saveUserProfileSuccess())
    yield put(addNotification('Informazioni aggiornate'))
  } catch (error) {
    yield put(saveUserProfileFailure(error))
    yield put(addNotification(uiHelper.error(error), true))
  }
}
