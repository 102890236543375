export const config = {
  buyProduct: {
    serviceid: 'service_qjxgqd6',
    userid: 'user_DriBfjwAEZdNoxPEFLknY',
    token: '5a3bd504535d9a559cad8a40f2bb857d',
    templateId: 'template_hyihvxz'
  },
  badgeRequest: {
    serviceid: 'service_qjxgqd6',
    userid: 'user_DriBfjwAEZdNoxPEFLknY',
    token: '5a3bd504535d9a559cad8a40f2bb857d',
    templateId: 'template_eupmwlc'
  }
}
