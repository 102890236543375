import React from 'react'
import { menuConstants } from '../../../../constants'
import { NavLink } from 'react-router-dom'

import css from './Menu.module.scss'

const Menu = (props) => (
  <ul className={css.UlNav}>
    {menuConstants.links.map((l) => (
      <li key={l.id}>
        <NavLink
          onClick={props.navClickHandler}
          to={l.to}
          className={css.NavLink}
        >
          {l.label}
        </NavLink>
      </li>
    ))}
    {props.identity?.admin && (
      <React.Fragment>
        <li className={css.LiAdmin}>
          <NavLink
            to="/admin/badges"
            onClick={props.navClickHandler}
            className={css.NavLink}
          >
            Admin Badges
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/store"
            onClick={props.navClickHandler}
            className={css.NavLink}
          >
            Admin Store
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin/users"
            onClick={props.navClickHandler}
            className={css.NavLink}
          >
            Admin Utenti
          </NavLink>
        </li>
      </React.Fragment>
    )}
    <li className={css.LiLogout}>
      <button className={css.NavLink} onClick={props.logoutHandler}>
        Esci
      </button>
    </li>
  </ul>
)

export default Menu
