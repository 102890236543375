import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { adsRef } from '../../database'
import { onSnapshot } from 'firebase/firestore'

import { updateAd, removeAd, addNotification } from '../actions'
import { appearanceConstants } from '../../constants'

export let adsSnap

export function* initAdSaga() {
  try {
    const listener = eventChannel((emit) => {
      adsSnap = onSnapshot(adsRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => adsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateAd({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateAd({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeAd({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: must do something
    console.log(error)
  }
}

export function* saveAdSaga({ id, payload }) {
  try {
    // if (!id) {
    //   yield adsRef.add(payload)
    //   yield history.push(`/admin`)
    // } else {
    //   yield adsRef.doc(id).update({ ...payload })
    // }
    // yield put(addNotification(appearanceConstants.messages.savesuccess))
    yield put(addNotification('not implemented', true))
  } catch (error) {
    yield put(addNotification(appearanceConstants.messages.savefailure))
  }
}
