import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth } from '../../firestore'

import {
  browserLocalPersistence,
  GoogleAuthProvider,
  setPersistence,
  onAuthStateChanged,
  signOut,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'

import {
  initUser,
  loginFailure,
  loginSuccess,
  logoutUserFailure,
  logoutUserSuccess,
  validateAuthFailure,
  validateAuthSuccess,
  addNotification,
  redirect,
  verifyUser,
  resetActivity,
  resetAd,
  resetBadge,
  resetProduct,
  resetRequest,
  resetUser,
  resetWeapon,
  recoverPasswordSuccess,
  recoverPasswordFailure
} from '../actions'

import { adsSnap } from './ad.sagas'
import { activitiesSnap } from './activity.sagas'
import { badgesSnap } from './badge.sagas'
import { productsSnap } from './product.sagas'
import { requestsSnap } from './request.sagas'
import { userSnap } from './user.sagas'
import {
  adminSnap,
  adminRequestsSnap,
  adminActivitiesSnap
} from './admin.sagas'
import { uiHelper } from '../../helpers'

export function* signUpEmailPasswordSaga({ payload }) {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          return createUserWithEmailAndPassword(
            auth,
            payload.email,
            payload.password
          )
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(addNotification(uiHelper.errorMessage(error), true))
  }
}

export function* loginEmailPasswordSaga({ payload }) {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          return signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password
          )
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(addNotification(uiHelper.errorMessage(error), true))
  }
}

export function* recoverPasswordSaga({ payload }) {
  try {
    const result = () =>
      new Promise((resolve) => {
        sendPasswordResetEmail(auth, payload.email)
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            resolve(error)
          })
      })
    yield result()
    yield put(addNotification('Password recovery email sent'))
    yield put(recoverPasswordSuccess())
  } catch (error) {
    yield put(addNotification(uiHelper.errorMessage(error), true))
    yield put(recoverPasswordFailure(uiHelper.errorMessage(error)))
  }
}

export function* loginGoogleSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new GoogleAuthProvider()
          provider.addScope('https://www.googleapis.com/auth/userinfo.email')
          provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(addNotification(uiHelper.errorMessage(error), true))
  }
}

export function* loginFacebookSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new FacebookAuthProvider()
          provider.addScope('email')
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(addNotification(uiHelper.errorMessage(error), true))
  }
}

export function* loginTwitterSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new TwitterAuthProvider()
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(addNotification(uiHelper.errorMessage(error), true))
  }
}

export function* validateAuthSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = onAuthStateChanged(auth, (result) => {
        if (result) {
          emit({
            uid: result.uid,
            displayName: result.displayName
          })
        } else {
          emit(false)
        }
      })
      return () => unsubscribe
    })
    while (true) {
      const user = yield take(listener)
      if (user) {
        yield put(validateAuthSuccess())
        yield put(verifyUser(user))
        yield put(initUser(user))
        if (window.location.pathname === '/') {
          yield put(redirect(`/dashboard`))
        }
      } else {
        yield put(validateAuthFailure())
        if (
          window.location.pathname !== '/' &&
          window.location.pathname !== '/privacy'
        ) {
          yield put(redirect(`/`))
        }
      }
    }
  } catch (error) {
    console.log(error)
    yield put(validateAuthFailure())
    if (window.location.pathname !== '/') {
      yield put(redirect(`/`))
    }
  }
}

export function* logoutUserSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = signOut(auth)
        .then(() => {
          emit(true)
        })
        .catch((error) => {
          emit(error)
        })
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)

      adsSnap()
      activitiesSnap()
      badgesSnap()
      productsSnap()
      requestsSnap()
      userSnap()

      if (adminSnap) {
        adminSnap()
      }
      if (adminRequestsSnap) {
        adminRequestsSnap()
      }
      if (adminActivitiesSnap) {
        adminActivitiesSnap()
      }

      yield put(resetActivity())
      yield put(resetAd())
      yield put(resetBadge())
      yield put(resetProduct())
      yield put(resetRequest())
      yield put(resetUser())
      yield put(resetWeapon())

      yield put(logoutUserSuccess())
      yield put(redirect(`/`))
    }
  } catch (error) {
    console.log(error)
    yield put(logoutUserFailure())
    yield put(redirect(`/`))
  }
}
