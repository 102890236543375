import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { productsRef, usersRef, database } from '../../database'
import { timeHelper } from '../../helpers'
import emailjs from 'emailjs-com'
import { config } from '../../config'

import {
  updateProduct,
  removeProduct,
  addNotification,
  buyProductFailure,
  buyProductSuccess,
  cancelBuyProductSuccess,
  cancelBuyProductFailure,
  redirect
} from '../actions'
import { appearanceConstants, firestoreConstants } from '../../constants'
import {
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  writeBatch,
  getDoc,
  collection
} from 'firebase/firestore'

export let productsSnap

export function* initProductSaga() {
  try {
    const listener = eventChannel((emit) => {
      productsSnap = onSnapshot(productsRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => productsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateProduct({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateProduct({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeProduct({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: must do something
    console.log(error)
  }
}

export function* saveProductSaga({ id, payload }) {
  try {
    if (!id) {
      const docRef = yield addDoc(productsRef, payload)
      yield put(redirect(`/admin/store/${docRef.id}`))

      yield put(addNotification(appearanceConstants.messages.savesuccess))
    } else {
      yield updateDoc(doc(database, firestoreConstants.products, id), payload)

      yield put(addNotification(appearanceConstants.messages.savesuccess))
    }
  } catch (error) {
    console.log(error)

    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}

export function* deleteProductSaga(payload) {
  try {
    yield deleteDoc(doc(database, firestoreConstants.products, payload.id))
    yield put(redirect(`/admin/store`))

    yield put(addNotification(appearanceConstants.messages.deletesuccess))
  } catch (error) {
    console.log(error)

    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}

export function* buyProductSaga(action) {
  try {
    const product = action.payload.product
    const userid = action.payload.userid
    const useruid = action.payload.useruid
    const note = action.payload.note

    let user = yield getDoc(doc(usersRef, userid))
    user = user.data()

    const batch = yield writeBatch(database)
    batch.set(doc(collection(database, firestoreConstants.activities)), {
      tag: product.tag,
      date: timeHelper.currentTime(),
      type: appearanceConstants.activities.store,
      uid: useruid,
      title: product.title,
      image: product.image,
      points: product.price,
      note
    })
    // update product
    batch.update(doc(database, firestoreConstants.users, userid), {
      coins: user.coins - product.price
    })
    if (product.isLimited) {
      batch.update(doc(database, firestoreConstants.products, product.id), {
        availability: product.availability - 1
      })
    }
    batch.set(doc(collection(database, firestoreConstants.logs)), {
      action: 'buy_product',
      note,
      userid,
      useruid,
      time: timeHelper.currentTime()
    })
    if (user.email && user.email !== '') {
      yield emailjs.send(
        config.buyProduct.serviceid,
        config.buyProduct.templateId,
        {
          to_name: user.displayName,
          product_name: product.title,
          user_email: user.email,
          buy_note: note
        },
        config.buyProduct.userid
      )
    }
    yield batch.commit()

    yield put(buyProductSuccess())
    yield put(addNotification(appearanceConstants.messages.buyproductsuccess))
  } catch (error) {
    console.log(error)
    yield put(buyProductFailure())
    yield put(
      addNotification(appearanceConstants.messages.buyproductfailure, true)
    )
  }
}

export function* cancelBuyProductSaga(action) {
  try {
    const activity = action.payload.activity
    const userid = action.payload.userid
    const product = action.payload.product

    let user = yield getDoc(doc(usersRef, userid))
    user = user.data()

    const batch = yield writeBatch(database)
    batch.delete(doc(database, firestoreConstants.activities, activity.id))
    batch.update(doc(database, firestoreConstants.users, userid), {
      coins: user.coins + activity.points
    })
    if (product.isLimited) {
      batch.update(doc(database, firestoreConstants.products, product.id), {
        availability: product.availability + 1
      })
    }
    yield batch.commit()

    yield put(cancelBuyProductSuccess())
    yield put(
      addNotification(appearanceConstants.messages.cancelbuyproductsuccess)
    )
  } catch (error) {
    console.log(error)
    yield put(cancelBuyProductFailure())
    yield put(
      addNotification(
        appearanceConstants.messages.cancelbuyproductfailure,
        true
      )
    )
  }
}
