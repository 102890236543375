import { adConstants } from '../constants'

export const initAd = () => {
  return {
    type: adConstants.AD_INIT
  }
}

export const updateAd = (payload) => {
  return {
    type: adConstants.AD_UPDATE,
    payload
  }
}

export const removeAd = (payload) => {
  return {
    type: adConstants.AD_REMOVE,
    payload
  }
}

export const resetAd = () => {
  return {
    type: adConstants.AD_RESET
  }
}
