import { uiConstants } from '../constants'
import { timeHelper, securityHelper } from '../../helpers'

export const toggleMenu = () => {
  return {
    type: uiConstants.TOGGLE_MENU
  }
}

export const closeMenu = () => {
  return {
    type: uiConstants.CLOSE_MENU
  }
}

export const showQrcode = (payload) => {
  return {
    type: uiConstants.SHOW_QRCODE,
    payload
  }
}

export const hideQrcode = () => {
  return {
    type: uiConstants.HIDE_QRCODE
  }
}

export const showBarcode = (payload) => {
  return {
    type: uiConstants.SHOW_BARCODE,
    payload
  }
}

export const hideBarcode = () => {
  return {
    type: uiConstants.HIDE_BARCODE
  }
}

export function addNotification(message, error = false) {
  return (dispatch) => {
    const notification = {
      message,
      time: timeHelper.currentTime(),
      guid: securityHelper.guid(),
      error
    }
    dispatch(pushNotificationToQueue(notification))
    setTimeout(() => {
      dispatch(removeNotification(notification.guid))
    }, 5000)
  }
}

function pushNotificationToQueue(payload) {
  return {
    type: uiConstants.PUSH_NOTIFICATION,
    payload
  }
}

export function removeNotification(guid) {
  return {
    type: uiConstants.REMOVE_NOTIFICATION,
    payload: guid
  }
}

export function resetUi() {
  return {
    type: uiConstants.UI_RESET
  }
}
