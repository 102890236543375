export const appearanceConstants = {
  dateFormat: 'DD MMM YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  hourFormat: 'HH:mm',
  dayOfWeek: '0',
  timeToDateInput: 'YYYY-MM-DD',
  baseUrl: 'https://tacti.club/',
  urls: {
    new: '_new'
  },
  messages: {
    savesuccess: 'Salvato con successo',
    deletesuccess: 'Eliminato con successo',
    savefailure: 'Errore nel salvataggio',
    badgeassignsuccess: 'Badge assegnato con successo',
    badgeassignfailure: 'Errore assegnazione badge',
    badgeremovesuccess: 'Badge rimosso con successo',
    badgeremovefailure: 'Errore rimozione badge',
    usersavesuccess: 'Utente salvato con successo',
    usersavefailure: 'Errore salvataggio dati utente',
    buyproductsuccess: 'Prodotto acquistato con successo',
    buyproductfailure: "Errore nell'acquisto del prodotto, riprovare",
    cancelbuyproductsuccess: 'Acquisto annullato con successo',
    cancelbuyproductfailure: 'Errore annullamento acquisto, riprovare',
    badgerequestsuccess: 'Richiesta badge avvenuta con successo',
    badgerequestfailure: 'errore nella richiesta badge, riprovare',
    requestremovesuccess: 'Richiesta eliminata con successo',
    requestremovefailure: 'Errore rimozione richiesta, riprovare'
  },
  activities: { badge: 'badge', store: 'store' }
}
