import React from 'react'
import { Outlet } from 'react-router-dom'

import css from './Layout.module.scss'
import NotificationQueue from '../../UI/NotificationQueue/NotificationQueue'
import Navigator from './Navigator/Navigator'
import CheckAuth from './CheckAuth/CheckAuth'
import ReduxLoader from './ReduxLoader/ReduxLoader'

const Layout = () => (
  <div className={css.Layout}>
    <CheckAuth />
    <Navigator />
    <Outlet />
    <NotificationQueue />
    <ReduxLoader />
  </div>
)

export default Layout
