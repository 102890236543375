import { authConstants } from '../constants'

export const signupEmailPassword = (payload) => {
  return {
    type: authConstants.AUTH_SIGNUP_EMAIL_PASSWORD,
    payload
  }
}

export const recoverPassword = (payload) => {
  return {
    type: authConstants.AUTH_RECOVER_PASSWORD,
    payload
  }
}

export const recoverPasswordSuccess = () => {
  return {
    type: authConstants.AUTH_RECOVER_PASSWORD_SUCCESS
  }
}

export const recoverPasswordFailure = (payload) => {
  return {
    type: authConstants.AUTH_RECOVER_PASSWORD_FAILURE,
    payload
  }
}

export const loginEmailPassword = (payload) => {
  return {
    type: authConstants.AUTH_LOGIN_EMAIL_PASSWORD,
    payload
  }
}

export const loginGoogle = () => {
  return {
    type: authConstants.AUTH_LOGIN_GOOGLE
  }
}

export const loginFacebook = () => {
  return {
    type: authConstants.AUTH_LOGIN_FACEBOOK
  }
}

export const loginTwitter = () => {
  return {
    type: authConstants.AUTH_LOGIN_TWITTER
  }
}
export const checkUser = (payload) => {
  return {
    type: authConstants.AUTH_LOGIN_CHECK_USER,
    payload
  }
}

export const loginSuccess = () => {
  return {
    type: authConstants.AUTH_LOGIN_SUCCESS
  }
}

export const loginFailure = (payload) => {
  return {
    type: authConstants.AUTH_LOGIN_FAILURE,
    payload
  }
}

export const validateAuth = () => {
  return {
    type: authConstants.AUTH_VALIDATE
  }
}

export const validateAuthSuccess = () => {
  return {
    type: authConstants.AUTH_VALIDATE_SUCCESS
  }
}

export const validateAuthFailure = () => {
  return {
    type: authConstants.AUTH_VALIDATE_FAILURE
  }
}

export const logoutUser = () => {
  return {
    type: authConstants.AUTH_LOGOUT
  }
}

export const logoutUserSuccess = () => {
  return {
    type: authConstants.AUTH_LOGOUT_SUCCESS
  }
}

export const logoutUserFailure = () => {
  return {
    type: authConstants.AUTH_LOGOUT_FAILURE
  }
}
