import React, { useEffect, useState } from 'react'

import css from './StatusBar.module.scss'
import Coin from '../../../UI/Coin/Coin'
import { uiHelper } from '../../../../helpers'
import MenuToggler from './MenuToggler/MenuToggler'
import QRCode from 'react-qr-code'
import { appearanceConstants } from '../../../../constants'

const StatusBar = ({ user, menuIsOpen, showQrcode, closeMenu }) => {
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  )

  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect())
  }

  useEffect(() => {
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  })

  const qrcodeUrl = `${appearanceConstants.baseUrl}admin/users/${user?.id}`

  return (
    <div
      className={`${css.StatusBar} ${
        Math.abs(bodyOffset.top) > 0 ? css.Shadow : ''
      }`}
    >
      <div className={`${css.Menu} ${menuIsOpen ? css.MenuOpen : ''}`}>
        <MenuToggler />
      </div>
      {uiHelper.formatNumber(user?.coins)} <Coin />
      <span className={css.ProfileName}>{user?.displayName}</span>
      {user?.vip && (
        <span className={css.Vip}>
          <i className="fas fa-crown"></i>
        </span>
      )}
      <span onClick={() => showQrcode(qrcodeUrl)} className={css.QrContainer}>
        <QRCode value={qrcodeUrl} size={30} fgColor={'#255f85'} />
      </span>
      {menuIsOpen && (
        <div onClick={closeMenu} className={css.MobileMenuOpenBkg}></div>
      )}
    </div>
  )
}

export default StatusBar
