import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { weaponsRef, database } from '../../database'

import {
  updateWeapon,
  removeWeapon,
  addNotification,
  redirect
} from '../actions'
import { appearanceConstants, firestoreConstants } from '../../constants'
import {
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc
} from 'firebase/firestore'

export let weaponsSnap

export function* initWeaponSaga(action) {
  try {
    const listener = eventChannel((emit) => {
      const q = query(weaponsRef, where('uid', '==', action.payload))
      weaponsSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => weaponsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateWeapon({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateWeapon({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeWeapon({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: must do something
    console.log(error)
  }
}

export function* saveWeaponSaga({ id, payload }) {
  try {
    if (!id) {
      yield addDoc(weaponsRef, payload)
      yield put(redirect(`/poligono`))
    } else {
      yield updateDoc(doc(database, firestoreConstants.weapons, id), payload)
    }
    yield put(addNotification(appearanceConstants.messages.savesuccess))
  } catch (error) {
    console.log(error)
    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}

export function* deleteWeaponSaga(payload) {
  try {
    yield deleteDoc(doc(database, firestoreConstants.weapons, payload.id))
    yield put(redirect(`/poligono`))

    yield put(addNotification(appearanceConstants.messages.deletesuccess))
  } catch (error) {
    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}
