import React, { useState, useEffect, useRef, useCallback } from 'react'
// import { storageRef } from '../../../database'
import { storage } from '../../../firestore'
import { ref, getDownloadURL } from 'firebase/storage'

import Placeholder from '../../../assets/placeholder.svg'

const Image = ({ src, alt }) => {
  const [image, setImage] = useState(Placeholder)

  const componentIsMounted = useRef(true)

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  const getImageLink = useCallback(() => {
    if (src) {
      setImage(Placeholder)
      getDownloadURL(ref(storage, src))
        .then((url) => {
          setImage(url)
          // window.localStorage.setItem(window.btoa(src), url)
        })
        .catch((error) => {
          if (componentIsMounted.current) setImage(Placeholder)
        })
    }
  }, [src])

  const refreshImageUrl = () => {
    getImageLink()
  }

  useEffect(() => {
    const cache = window.localStorage.getItem(window.btoa(src))
    if (!cache) {
      getImageLink()
    } else {
      setImage(cache)
    }
  }, [src, getImageLink])

  return <img src={image} onError={refreshImageUrl} alt={alt} />
}

export default Image
