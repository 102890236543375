import { put, take } from 'redux-saga/effects'
import { activitiesRef, database, requestsRef, usersRef } from '../../database'
import { eventChannel } from 'redux-saga'
import { appearanceConstants, firestoreConstants } from '../../constants'
import {
  onSnapshot,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  writeBatch,
  collection
} from 'firebase/firestore'

import {
  addNotification,
  adminAssignBadgeSuccess,
  adminRemoveBadgeFailure,
  adminRemoveBadgeSuccess,
  adminAssignBadgeFailure,
  adminSaveUserFailure,
  adminSaveUserSuccess,
  adminUpdateUser,
  adminRemoveUser,
  adminRemoveRequestSuccess,
  adminRemoveRequestFailure,
  adminRequestUpdate,
  adminRequestRemove,
  adminRemoveUserActivity,
  adminUpdateUserActivity
} from '../actions'

export let adminSnap
export let adminRequestsSnap
export let adminActivitiesSnap

export function* initAdminUserSaga() {
  try {
    const listener = eventChannel((emit) => {
      adminSnap = onSnapshot(usersRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => adminSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id

      if (change.type === 'added') {
        yield put(adminUpdateUser({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(adminUpdateUser({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(adminRemoveUser({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(addNotification('Error loading users', true))
  }
}

export function* initAdminRequestSaga() {
  try {
    const listener = eventChannel((emit) => {
      adminRequestsSnap = onSnapshot(requestsRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => adminRequestsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(adminRequestUpdate({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(adminRequestUpdate({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(adminRequestRemove({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(addNotification('Error loading requests', true))
  }
}

export function* assignBadgeUserSaga(action) {
  try {
    const badge = action.payload.badge
    const userid = action.payload.userid
    const useruid = action.payload.useruid

    const profileRef = yield getDoc(
      doc(database, firestoreConstants.users, userid)
    )
    const user = { id: profileRef.id, ...profileRef.data() }

    const batch = yield writeBatch(database)
    batch.set(doc(collection(database, firestoreConstants.activities)), {
      tag: badge.tag,
      date: action.payload.date,
      type: appearanceConstants.activities.badge,
      uid: useruid,
      title: badge.description,
      image: badge.image,
      points: badge.points
    })
    batch.update(doc(database, firestoreConstants.users, userid), {
      coins: user.coins + badge.points
    })
    // remove request
    const q = query(
      requestsRef,
      where('uid', '==', useruid),
      where('tag', '==', badge.tag)
    )
    const querySnapshot = yield getDocs(q)
    querySnapshot.forEach((x) => {
      batch.delete(doc(database, firestoreConstants.requests, x.id))
    })

    yield batch.commit()

    yield put(adminAssignBadgeSuccess())
    yield put(addNotification(appearanceConstants.messages.badgeassignsuccess))
  } catch (error) {
    console.log(error)
    yield put(adminAssignBadgeFailure(error.message))
    yield put(
      addNotification(appearanceConstants.messages.badgeassignfailure, true)
    )
  }
}

export function* removeBadgeUserSaga(action) {
  try {
    const userid = action.payload.userid
    // const useruid = action.payload.useruid
    const activity = action.payload.activity

    const profileRef = yield getDoc(
      doc(database, firestoreConstants.users, userid)
    )
    const user = { id: profileRef.id, ...profileRef.data() }

    const batch = yield writeBatch(database)
    batch.delete(doc(database, firestoreConstants.activities, activity.id))

    if (activity.type === appearanceConstants.activities.badge) {
      batch.update(doc(database, firestoreConstants.users, userid), {
        coins: user.coins - activity.points
      })
    } else {
      batch.update(doc(database, firestoreConstants.users, userid), {
        coins: user.coins + activity.points
      })
    }
    yield batch.commit()

    yield put(adminRemoveBadgeSuccess())
    yield put(addNotification(appearanceConstants.messages.badgeremovesuccess))
  } catch (error) {
    console.log(error)
    yield put(adminRemoveBadgeFailure(error.message))
    yield put(
      addNotification(appearanceConstants.messages.badgeremovefailure, true)
    )
  }
}

export function* saveUserSaga(action) {
  try {
    const id = action.payload.id
    const data = action.payload.data

    yield updateDoc(doc(database, firestoreConstants.users, id), data)

    yield put(adminSaveUserSuccess(data))
    yield put(addNotification(appearanceConstants.messages.usersavesuccess))
  } catch (error) {
    console.log(error)
    yield put(adminSaveUserFailure(error.message))
    yield put(
      addNotification(appearanceConstants.messages.usersavefailure, true)
    )
  }
}

export function* removeRequestSaga(action) {
  try {
    yield deleteDoc(
      doc(database, firestoreConstants.requests, action.payload.id)
    )
    yield put(adminRemoveRequestSuccess(action.payload))
    yield put(
      addNotification(appearanceConstants.messages.requestremovesuccess)
    )
  } catch (error) {
    console.log(error)
    yield put(adminRemoveRequestFailure(error.message))
    yield put(
      addNotification(appearanceConstants.messages.requestremovefailure, true)
    )
  }
}

export function* adminInitUserActivitySaga(action) {
  try {
    const listener = eventChannel((emit) => {
      const q = query(activitiesRef, where('uid', '==', action.payload))
      adminActivitiesSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => adminActivitiesSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id

      if (change.type === 'added') {
        yield put(adminUpdateUserActivity({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(adminUpdateUserActivity({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(adminRemoveUserActivity({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
