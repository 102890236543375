import { db } from './firestore'
import { collection } from 'firebase/firestore'
import { firestoreConstants } from './constants'

// import { enableIndexedDbPersistence } from 'firebase/firestore'

// enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code === 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//     // ...
//   } else if (err.code === 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//     // ...
//   }
// })

export const database = db

export const usersRef = collection(db, firestoreConstants.users)
export const badgesRef = collection(db, firestoreConstants.badges)
export const activitiesRef = collection(db, firestoreConstants.activities)
export const productsRef = collection(db, firestoreConstants.products)
export const adsRef = collection(db, firestoreConstants.ads)
export const logsRef = collection(db, firestoreConstants.logs)
export const requestsRef = collection(db, firestoreConstants.requests)
export const weaponsRef = collection(db, firestoreConstants.weapons)
