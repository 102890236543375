import { userConstants } from '../constants'

const initialState = {
  identity: null,
  loading: false,
  error: null
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_UPDATE:
      return {
        ...state,
        loading: false,
        identity: action.payload
      }
    case userConstants.USER_REMOVE:
      return {
        ...state,
        loading: false,
        identity: null
      }
    case userConstants.USER_RESET:
      return {
        ...initialState
      }
    case userConstants.USER_SAVE:
      return {
        ...state,
        loading: true,
        error: null
      }
    case userConstants.USER_SAVE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case userConstants.USER_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
