export const menuConstants = {
  links: [
    { id: 'dashboard', label: 'Dashboard', to: '/dashboard' },
    {
      id: 'activity',
      label: 'Attività',
      to: '/activity'
    },
    {
      id: 'tc',
      label: 'Tacti Club',
      to: '/tacti-club'
    },
    {
      id: 'hd',
      label: 'Home Defense',
      to: '/home-defense'
    },
    {
      id: 'gd',
      label: 'Girls Defense',
      to: '/girls-defense'
    },
    {
      id: 'dkm',
      label: 'Defendo Krav Maga',
      to: '/defendo-krav-maga'
    },
    {
      id: 'killhouse',
      label: 'Kill House',
      to: '/kill-house'
    },
    {
      id: 'casabella',
      label: 'Armeria Casabella',
      to: '/armeria-casabella'
    },
    {
      id: 'store',
      label: 'Store',
      to: '/store'
    },
    {
      id: 'faq',
      label: 'Faq',
      to: '/faq'
    },
    {
      id: 'poligono',
      label: 'Poligono di tiro',
      to: '/poligono'
    },
    {
      id: 'settings',
      label: 'Impostazioni',
      to: '/settings'
    }
  ]
}
