import { productConstants } from '../constants'

export const initProduct = () => {
  return {
    type: productConstants.PRODUCT_INIT
  }
}

export const updateProduct = (payload) => {
  return {
    type: productConstants.PRODUCT_UPDATE,
    payload
  }
}

export const removeProduct = (payload) => {
  return {
    type: productConstants.PRODUCT_REMOVE,
    payload
  }
}

export const saveProduct = (id, payload) => {
  return {
    type: productConstants.PRODUCT_SAVE,
    id,
    payload
  }
}

export const deleteProduct = (id) => {
  return {
    type: productConstants.PRODUCT_DELETE,
    id
  }
}

export const buyProduct = (payload) => {
  return {
    type: productConstants.PRODUCT_BUY,
    payload
  }
}

export const buyProductSuccess = () => {
  return {
    type: productConstants.PRODUCT_BUY_SUCCESS
  }
}

export const buyProductFailure = () => {
  return {
    type: productConstants.PRODUCT_BUY_FAILURE
  }
}

export const cancelBuyProduct = (payload) => {
  return {
    type: productConstants.PRODUCT_CANCEL_BUY,
    payload
  }
}

export const cancelBuyProductSuccess = () => {
  return {
    type: productConstants.PRODUCT_CANCEL_BUY_SUCCESS
  }
}

export const cancelBuyProductFailure = () => {
  return {
    type: productConstants.PRODUCT_CANCEL_BUY_FAILURE
  }
}

export const resetProduct = () => {
  return {
    type: productConstants.PRODUCT_RESET
  }
}
