import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  initActivity,
  initBadge,
  initProduct,
  initAd,
  initRequest,
  initWeapon
} from '../../../../redux/actions'

const LoadCollections = ({ user, activity }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initBadge())
    dispatch(initProduct())
    dispatch(initAd())
  }, [dispatch])

  useEffect(() => {
    if (user.identity && !activity.list) {
      dispatch(initActivity(user.identity.uid))
      dispatch(initRequest(user.identity.uid))
      dispatch(initWeapon(user.identity.uid))
    }
  }, [dispatch, user, activity])

  return <React.Fragment />
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(LoadCollections)
