export const firestoreConstants = {
  users: 'users',
  badges: 'badges',
  activities: 'activities',
  products: 'products',
  ads: 'ads',
  logs: 'logs',
  requests: 'requests',
  weapons: 'weapons'
}
