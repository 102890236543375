import { userConstants } from '../constants'

export const initUser = (payload) => {
  return {
    type: userConstants.USER_INIT,
    payload
  }
}

export const updateUser = (payload) => {
  return {
    type: userConstants.USER_UPDATE,
    payload
  }
}

export const removeUser = (payload) => {
  return {
    type: userConstants.USER_REMOVE,
    payload
  }
}

export const verifyUser = (payload) => {
  return {
    type: userConstants.USER_VERIFY,
    payload
  }
}

export const resetUser = () => {
  return {
    type: userConstants.USER_RESET
  }
}

export const saveUserProfile = (payload) => {
  return {
    type: userConstants.USER_SAVE,
    payload
  }
}

export const saveUserProfileSuccess = () => {
  return {
    type: userConstants.USER_SAVE_SUCCESS
  }
}

export const saveUserProfileFailure = (payload) => {
  return {
    type: userConstants.USER_SAVE_FAILURE,
    payload
  }
}
