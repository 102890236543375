import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import css from './Ads.module.scss'
import Image from '../Image/Image'

const Ads = (props) => (
  <ul className={css.UlAds}>
    {(props.ad.list || []).map((ad) => (
      <li key={ad.id}>
        <Link to="/store" className={css.Link}>
          <Image src={ad.image} alt={ad.title} />
        </Link>
      </li>
    ))}
  </ul>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Ads)
