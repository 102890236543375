import { badgeConstants } from '../constants'

const initialState = {
  list: null
}

export default function badge(state = initialState, action) {
  switch (action.type) {
    case badgeConstants.BADGE_UPDATE:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
            ? state.list.concat(action.payload)
            : state.list.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              })
      }
    case badgeConstants.BADGE_REMOVE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        })
      }
    case badgeConstants.BADGE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
