import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Outlet, useLocation } from 'react-router-dom'

import css from './AuthLayout.module.scss'
import LoadCollections from './LoadCollections/LoadCollections'
import Menu from './Menu/Menu'
import Ads from '../../UI/Ads/Ads'
import StatusBar from './StatusBar/StatusBar'
import ScrollTop from './ScrollTop/ScrollTop'

import {
  logoutUser,
  closeMenu,
  showQrcode,
  showBarcode
} from '../../../redux/actions'
import Footer from './Footer/Footer'
import Logo from '../../../assets/logo_white.svg'
import QrcodeMagnifier from '../../UI/QrcodeMagnifier/QrcodeMagnifier'
import BarcodeMagnifier from '../../UI/BarcodeMagnifier/BarcodeMagnifier'
import PageLoader from '../../UI/PageLoader/PageLoader'
import { menuConstants } from '../../../constants'

const AuthLayout = (props) => {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <ul className={css.UlBone}>
      <li
        className={`${css.BoneMenu} ${props.ui.menuIsOpen ? css.MenuOpen : ''}`}
      >
        <LoadCollections />
        <Link
          to={menuConstants.links[0].to}
          onClick={props.closeMenu}
          className={css.LinkLogo}
        >
          <img src={Logo} alt="logo" />
        </Link>
        <Menu
          logoutHandler={props.logoutUser}
          navClickHandler={props.closeMenu}
          identity={props.user.identity}
        />
      </li>
      <li className={css.BoneContent}>
        <StatusBar
          user={props.user.identity}
          menuIsOpen={props.ui.menuIsOpen}
          showQrcode={props.showQrcode}
          closeMenu={props.closeMenu}
          showBarcode={props.showBarcode}
        />
        <ul className={css.UlContent}>
          <li className={css.Content}>
            <Outlet />
          </li>
          <li className={css.Sponsor}>
            <Ads />
          </li>
        </ul>
        <Footer />
        <QrcodeMagnifier />
        <BarcodeMagnifier />
        <ScrollTop />
        {(props.product.loading || props.request.loading) && <PageLoader />}
      </li>
    </ul>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  logoutUser,
  closeMenu,
  showQrcode,
  showBarcode
})(AuthLayout)
