import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

var firebaseConfig = {
  apiKey: 'AIzaSyALihDvtWnIN4om3R4bFpfXIkHHy11dM-Y',
  authDomain: 'tacticlub-cbw52hd.firebaseapp.com',
  databaseURL: 'https://tacticlub-cbw52hd.firebaseio.com',
  projectId: 'tacticlub-cbw52hd',
  storageBucket: 'tacticlub-cbw52hd.appspot.com',
  messagingSenderId: '261103617836',
  appId: '1:261103617836:web:9910d983b928dcf250ce89',
  measurementId: 'G-34KXWMG2K5'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
