import { productConstants } from '../constants'

const initialState = {
  list: null,
  loading: false
}

export default function product(state = initialState, action) {
  switch (action.type) {
    case productConstants.PRODUCT_UPDATE:
      return {
        ...state,
        list: (state.list === null
          ? [action.payload]
          : state.list.findIndex((x) => x.id === action.payload.id) === -1
          ? state.list.concat(action.payload)
          : state.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        ).sort(
          (a, b) =>
            (a.hot === b.hot ? 0 : a.hot ? -1 : 1) ||
            (a.price > b.price ? 1 : b.price > a.price ? -1 : 0)
        )
      }
    case productConstants.PRODUCT_REMOVE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        })
      }
    case productConstants.PRODUCT_BUY:
    case productConstants.PRODUCT_CANCEL_BUY:
      return { ...state, loading: true }
    case productConstants.PRODUCT_BUY_SUCCESS:
    case productConstants.PRODUCT_BUY_FAILURE:
    case productConstants.PRODUCT_CANCEL_BUY_SUCCESS:
    case productConstants.PRODUCT_CANCEL_BUY_FAILURE:
      return { ...state, loading: false }
    case productConstants.PRODUCT_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
