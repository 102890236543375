export const uiConstants = {
  TOGGLE_MENU: '[Ui] Toggle menu',
  CLOSE_MENU: '[Ui] Close menu',
  SHOW_QRCODE: '[Ui] Show qrcode',
  HIDE_QRCODE: '[Ui] Hide qrcode',
  SHOW_BARCODE: '[Ui] Show barcode',
  HIDE_BARCODE: '[Ui] Hide barcode',
  PUSH_NOTIFICATION: '[Ui] Push notification',
  REMOVE_NOTIFICATION: '[Ui] Remove notification',
  UI_RESET: '[Ui] Reset'
}
