import { takeEvery } from 'redux-saga/effects'
import {
  activityConstants,
  adConstants,
  authConstants,
  badgeConstants,
  productConstants,
  userConstants,
  adminConstants,
  requestConstants,
  weaponConstants
} from '../constants'
import { initActivitySaga, saveActivitySaga } from './activity.sagas'
import { initAdSaga } from './ad.sagas'
import {
  // checkUserSaga,
  loginEmailPasswordSaga,
  loginFacebookSaga,
  loginGoogleSaga,
  // createUserSaga,
  validateAuthSaga,
  logoutUserSaga,
  loginTwitterSaga,
  signUpEmailPasswordSaga,
  recoverPasswordSaga
} from './auth.sagas'
import { deleteBadgeSaga, initBadgeSaga, saveBadgeSaga } from './badge.sagas'
import {
  buyProductSaga,
  deleteProductSaga,
  initProductSaga,
  saveProductSaga,
  cancelBuyProductSaga
} from './product.sagas'
import { verifyUserSaga, loadUserSaga, saveUserProfileSaga } from './user.sagas'
import {
  assignBadgeUserSaga,
  removeBadgeUserSaga,
  saveUserSaga,
  initAdminUserSaga,
  removeRequestSaga,
  initAdminRequestSaga,
  adminInitUserActivitySaga
} from './admin.sagas'
import { initRequestSaga, makeRequestSaga } from './request.sagas'
import {
  initWeaponSaga,
  saveWeaponSaga,
  deleteWeaponSaga
} from './weapon.sagas'

export function* watchAuth() {
  yield takeEvery(
    authConstants.AUTH_LOGIN_EMAIL_PASSWORD,
    loginEmailPasswordSaga
  )
  yield takeEvery(
    authConstants.AUTH_SIGNUP_EMAIL_PASSWORD,
    signUpEmailPasswordSaga
  )
  yield takeEvery(authConstants.AUTH_RECOVER_PASSWORD, recoverPasswordSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_GOOGLE, loginGoogleSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_FACEBOOK, loginFacebookSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_TWITTER, loginTwitterSaga)
  yield takeEvery(authConstants.AUTH_VALIDATE, validateAuthSaga)
  yield takeEvery(authConstants.AUTH_LOGOUT, logoutUserSaga)
}

export function* watchUser() {
  yield takeEvery(userConstants.USER_VERIFY, verifyUserSaga)
  yield takeEvery(userConstants.USER_INIT, loadUserSaga)
  yield takeEvery(userConstants.USER_SAVE, saveUserProfileSaga)
}

export function* watchBadge() {
  yield takeEvery(badgeConstants.BADGE_INIT, initBadgeSaga)
  yield takeEvery(badgeConstants.BADGE_SAVE, saveBadgeSaga)
  yield takeEvery(badgeConstants.BADGE_DELETE, deleteBadgeSaga)
}

export function* watchActivity() {
  yield takeEvery(activityConstants.ACTIVITY_INIT, initActivitySaga)
  yield takeEvery(activityConstants.ACTIVITY_SAVE, saveActivitySaga)
}

export function* watchProduct() {
  yield takeEvery(productConstants.PRODUCT_INIT, initProductSaga)
  yield takeEvery(productConstants.PRODUCT_SAVE, saveProductSaga)
  yield takeEvery(productConstants.PRODUCT_DELETE, deleteProductSaga)
  yield takeEvery(productConstants.PRODUCT_BUY, buyProductSaga)
  yield takeEvery(productConstants.PRODUCT_CANCEL_BUY, cancelBuyProductSaga)
}

export function* watchAd() {
  yield takeEvery(adConstants.AD_INIT, initAdSaga)
}

export function* watchRequest() {
  yield takeEvery(requestConstants.REQUEST_INIT, initRequestSaga)
  yield takeEvery(requestConstants.REQUEST_MAKE, makeRequestSaga)
}

export function* watchWeapon() {
  yield takeEvery(weaponConstants.WEAPON_INIT, initWeaponSaga)
  yield takeEvery(weaponConstants.WEAPON_SAVE, saveWeaponSaga)
  yield takeEvery(weaponConstants.WEAPON_DELETE, deleteWeaponSaga)
}

export function* watchAdmin() {
  yield takeEvery(adminConstants.ADMIN_USERS_INIT, initAdminUserSaga)
  yield takeEvery(adminConstants.ADMIN_REQUESTS_INIT, initAdminRequestSaga)
  // yield takeEvery(adminConstants.ADMIN_LOAD_USER, loadAdminUserSaga)
  yield takeEvery(adminConstants.ADMIN_ASSIGN_BADGE, assignBadgeUserSaga)
  yield takeEvery(adminConstants.ADMIN_REMOVE_BADGE, removeBadgeUserSaga)
  yield takeEvery(adminConstants.ADMIN_SAVE_USER, saveUserSaga)
  yield takeEvery(adminConstants.ADMIN_REMOVE_REQUEST, removeRequestSaga)
  yield takeEvery(
    adminConstants.ADMIN_USER_ACTIVITY_INIT,
    adminInitUserActivitySaga
  )
}
