export const adminConstants = {
  ADMIN_LOAD_USER: '[Admin] Load User',
  ADMIN_LOAD_USER_SUCCESS: '[Admin] Load User success',
  ADMIN_LOAD_USER_FAILURE: '[Admin] Load User failure',
  ADMIN_ASSIGN_BADGE: '[Admin] Assign badge',
  ADMIN_ASSIGN_BADGE_SUCCESS: '[Admin] Assign badge success',
  ADMIN_ASSIGN_BADGE_FAILURE: '[Admin] Assign badge failure',
  ADMIN_REMOVE_BADGE: '[Admin] Remove badge',
  ADMIN_REMOVE_BADGE_SUCCESS: '[Admin] Remove badge success',
  ADMIN_REMOVE_BADGE_FAILURE: '[Admin] Remove badge failure',
  ADMIN_SHOW_LOADER: '[Admin] Show loader',
  ADMIN_HIDE_LOADER: '[Admin] Hide loader',
  ADMIN_SAVE_USER: '[Admin] Save user',
  ADMIN_SAVE_USER_SUCCESS: '[Admin] Save user success',
  ADMIN_SAVE_USER_FAILURE: '[Admin] Save user failure',
  ADMIN_USERS_INIT: '[Admin] User init',
  ADMIN_USER_UPDATE: '[Admin] User update',
  ADMIN_USER_REMOVE: '[Admin] User remove',
  ADMIN_REMOVE_REQUEST: '[Admin] Remove request',
  ADMIN_REMOVE_REQUEST_SUCCESS: '[Admin] Remove request success',
  ADMIN_REMOVE_REQUEST_FAILURE: '[Admin] Remove request failure',
  ADMIN_REQUESTS_INIT: '[Admin] Load requests',
  ADMIN_REQUESTS_UPDATE: '[Admin] Load requests update',
  ADMIN_REQUESTS_REMOVE: '[Admin] Load requests remove',
  ADMIN_USER_ACTIVITY_INIT: '[Admin] User activity init',
  ADMIN_ACTIVITIES_UPDATE: '[Admin] User activity update',
  ADMIN_ACTIVITIES_REMOVE: '[Admin] User activity remove'
}
