export const userConstants = {
  USER_INIT: '[User] init',
  USER_UPDATE: '[User] Update',
  USER_REMOVE: '[User] Remove',
  USER_VERIFY: '[User] Verify',
  USER_RESET: '[User] Reset',
  USER_SAVE: '[User] Save',
  USER_SAVE_SUCCESS: '[User] Save Success',
  USER_SAVE_FAILURE: '[User] Save Failure'
}
