import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {
  watchActivity,
  watchAd,
  watchAuth,
  watchBadge,
  watchProduct,
  watchUser,
  watchAdmin,
  watchRequest,
  watchWeapon
} from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production'
})

sagaMiddleware.run(watchAuth)
sagaMiddleware.run(watchUser)
sagaMiddleware.run(watchBadge)
sagaMiddleware.run(watchActivity)
sagaMiddleware.run(watchProduct)
sagaMiddleware.run(watchAd)
sagaMiddleware.run(watchAdmin)
sagaMiddleware.run(watchRequest)
sagaMiddleware.run(watchWeapon)
