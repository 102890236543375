import { Link } from 'react-router-dom'

import css from './Privacy.module.scss'

const Privacy = () => (
  <Link to="/privacy" className={css.Link}>
    Privacy Policy
  </Link>
)

export default Privacy
