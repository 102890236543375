import React from 'react'
import { connect } from 'react-redux'

import css from './BarcodeMagnifier.module.scss'

import { hideBarcode } from '../../../redux/actions'
import Barcode from './Barcode/Barcode'

const BarcodeMagnifier = (props) => {
  if (!props.ui.barcode) return null

  return (
    <div className={css.BarCode} onClick={props.hideBarcode}>
      <span>
        <Barcode value={props.ui.barcode} />
      </span>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { hideBarcode })(BarcodeMagnifier)
