import { activityConstants } from '../constants'

export const initActivity = (payload) => {
  return {
    type: activityConstants.ACTIVITY_INIT,
    payload
  }
}

export const updateActivity = (payload) => {
  return {
    type: activityConstants.ACTIVITY_UPDATE,
    payload
  }
}

export const removeActivity = (payload) => {
  return {
    type: activityConstants.ACTIVITY_REMOVE,
    payload
  }
}

export const saveActivity = (id, payload) => {
  return {
    type: activityConstants.ACTIVITY_SAVE,
    id,
    payload
  }
}

export const resetActivity = () => {
  return {
    type: activityConstants.ACTIVITY_RESET
  }
}
