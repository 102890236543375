import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { activitiesRef, database } from '../../database'

import {
  updateActivity,
  removeActivity,
  addNotification,
  redirect
} from '../actions'
import { appearanceConstants, firestoreConstants } from '../../constants'
import {
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  doc
} from 'firebase/firestore'

export let activitiesSnap

export function* initActivitySaga(action) {
  try {
    const listener = eventChannel((emit) => {
      const q = query(activitiesRef, where('uid', '==', action.payload))
      activitiesSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => activitiesSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateActivity({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateActivity({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeActivity({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: must do something
    console.log(error)
  }
}

export function* saveActivitySaga({ id, payload }) {
  try {
    if (!id) {
      yield addDoc(activitiesRef, payload)
      yield put(redirect(`/admin`))
    } else {
      yield updateDoc(doc(database, firestoreConstants.activities, id), payload)
    }
    yield put(addNotification(appearanceConstants.messages.savesuccess))
  } catch (error) {
    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}
