import React from 'react'

import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <div className={css.SkChase}>
      <div className={css.SkChaseDot}></div>
      <div className={css.SkChaseDot}></div>
      <div className={css.SkChaseDot}></div>
      <div className={css.SkChaseDot}></div>
      <div className={css.SkChaseDot}></div>
      <div className={css.SkChaseDot}></div>
    </div>
  </div>
)

export default PageLoader
