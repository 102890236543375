const errorMessage = (error) => {
  try {
    const e = error.response?.data?.message ?? error.message ?? error
    if (!e) {
      throw new Error('Generic Error')
    }
    return e
  } catch {
    return 'Generic Error'
  }
}

function formatNumber(input) {
  if (!input) return 0
  return input.toLocaleString()
}

function breakLine(input) {
  return input.split('\n').map((value, key) => {
    return (
      <span className="common-span-spacer" key={key}>
        {value}
      </span>
    )
  })
}

const provider = (providerId) => {
  switch (providerId) {
    case 'twitter.com':
      return <i className="fab fa-twitter"></i>
    case 'facebook.com':
      return <i className="fab fa-facebook-f"></i>
    case 'emailpassword':
      return <i className="fas fa-envelope"></i>
    default:
      return <i className="fab fa-google"></i>
  }
}

export const uiHelper = {
  formatNumber,
  breakLine,
  provider,
  errorMessage
}
