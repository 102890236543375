import React from 'react'
import { connect } from 'react-redux'

import css from './MenuToggler.module.scss'
import { toggleMenu } from '../../../../../redux/actions'

const MenuToggler = (props) => (
  <div className={css.Mobile}>
    <button
      className={`hamburger hamburger--spin ${
        props.ui.menuIsOpen ? 'is-active' : ''
      }`}
      onClick={props.toggleMenu}
      aria-label="Menu"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  </div>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { toggleMenu })(MenuToggler)
