import React, { Suspense, lazy } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from './history'
import { store } from './redux/store'

import Layout from './components/Containers/Layout/Layout.jsx'
import AuthLayout from './components/Containers/AuthLayout/AuthLayout'
import PageLoader from './components/UI/PageLoader/PageLoader'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const PrivacyLazy = lazy(() => import('./components/Pages/Privacy/Privacy'))
const DashboardLazy = lazy(() =>
  import('./components/Pages/Dashboard/Dashboard')
)
const HomeDefenseLazy = lazy(() =>
  import('./components/Pages/HomeDefense/HomeDefense')
)
const StoreLazy = lazy(() => import('./components/Pages/Store/Store'))
const CasabellaLazy = lazy(() =>
  import('./components/Pages/Casabella/Casabella')
)
const ActivitiesLazy = lazy(() =>
  import('./components/Pages/Activities/Activities')
)
const TactiClubLazy = lazy(() =>
  import('./components/Pages/TactiClub/TactiClub')
)
const DefendoLazy = lazy(() => import('./components/Pages/Defendo/Defendo'))
const FaqLazy = lazy(() => import('./components/Pages/Faq/Faq'))
const AdminLazy = lazy(() => import('./components/Pages/Admin/Admin'))
const NotFoundLazy = lazy(() => import('./components/Pages/NotFound/NotFound'))
const RangeBagLazy = lazy(() => import('./components/Pages/RangeBag/RangeBag'))
const RangeBagEditorLazy = lazy(() =>
  import('./components/Pages/RangeBagEditor/RangeBagEditor')
)
const GirlsDefenseLazy = lazy(() =>
  import('./components/Pages/GirlsDefense/GirlsDefense')
)
const KillHouseLazy = lazy(() =>
  import('./components/Pages/KillHouse/KillHouse')
)
const SettingsLazy = lazy(() => import('./components/Pages/Settings/Settings'))

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />
              <Route path="/privacy" element={<PrivacyLazy />} />
              <Route element={<AuthLayout />}>
                <Route path="/dashboard" element={<DashboardLazy />} />
                <Route path="/home-defense" element={<HomeDefenseLazy />} />
                <Route path="/girls-defense" element={<GirlsDefenseLazy />} />
                <Route path="/store" element={<StoreLazy />} />
                <Route path="/armeria-casabella" element={<CasabellaLazy />} />
                <Route path="/activity" element={<ActivitiesLazy />} />
                <Route path="/tacti-club" element={<TactiClubLazy />} />
                <Route path="/kill-house" element={<KillHouseLazy />} />
                <Route path="/defendo-krav-maga" element={<DefendoLazy />} />
                <Route path="/faq" element={<FaqLazy />} />
                <Route path="/poligono" element={<RangeBagLazy />} />
                <Route path="/poligono/:id" element={<RangeBagEditorLazy />} />
                <Route path="/admin/*" element={<AdminLazy />} />
                <Route path="/settings" element={<SettingsLazy />} />
              </Route>
              <Route path="*" element={<NotFoundLazy history={history} />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
