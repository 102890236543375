import { uiConstants } from '../constants'

const initialState = {
  menuIsOpen: false,
  qrcode: null,
  barcode: null,
  notificationQueue: []
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case uiConstants.TOGGLE_MENU:
      return { ...state, menuIsOpen: !state.menuIsOpen }
    case uiConstants.CLOSE_MENU:
      return { ...state, menuIsOpen: false }
    case uiConstants.SHOW_QRCODE:
      return { ...state, qrcode: action.payload }
    case uiConstants.HIDE_QRCODE:
      return { ...state, qrcode: null }
    case uiConstants.SHOW_BARCODE:
      return { ...state, barcode: action.payload }
    case uiConstants.HIDE_BARCODE:
      return { ...state, barcode: null }
    case uiConstants.PUSH_NOTIFICATION:
      return {
        ...state,
        notificationQueue: state.notificationQueue.concat(action.payload)
      }
    case uiConstants.REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationQueue: state.notificationQueue.filter(
          (n) => n.guid !== action.payload
        )
      }
    default:
      return state
  }
}
