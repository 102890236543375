import { authConstants } from '../constants'

const initialState = {
  error: null,
  loading: false
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.AUTH_RECOVER_PASSWORD:
    case authConstants.AUTH_SIGNUP_EMAIL_PASSWORD:
    case authConstants.AUTH_LOGIN_EMAIL_PASSWORD:
    case authConstants.AUTH_LOGIN_FACEBOOK:
    case authConstants.AUTH_LOGIN_GOOGLE:
    case authConstants.AUTH_LOGIN_TWITTER:
    case authConstants.AUTH_LOGIN_CHECK_USER:
    case authConstants.AUTH_LOGIN_CREATE_USER:
    case authConstants.AUTH_VALIDATE:
      return { ...state, loading: true, error: null }
    case authConstants.AUTH_LOGIN_SUCCESS:
    case authConstants.AUTH_VALIDATE_SUCCESS:
      return { ...state, loading: false, error: null }
    case authConstants.AUTH_LOGIN_FAILURE:
    case authConstants.AUTH_VALIDATE_FAILURE:
      return { ...state, loading: false }
    case authConstants.AUTH_LOGOUT:
      return { ...state, loading: true, error: null }
    case authConstants.AUTH_LOGOUT_SUCCESS:
      return { ...state, loading: false, error: null }
    case authConstants.AUTH_RECOVER_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null }
    case authConstants.AUTH_RECOVER_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.payload }
    // TODO: logout failure
    default:
      return state
  }
}
