import { weaponConstants } from '../constants'

export const initWeapon = (payload) => {
  return {
    type: weaponConstants.WEAPON_INIT,
    payload
  }
}

export const updateWeapon = (payload) => {
  return {
    type: weaponConstants.WEAPON_UPDATE,
    payload
  }
}

export const removeWeapon = (payload) => {
  return {
    type: weaponConstants.WEAPON_REMOVE,
    payload
  }
}

export const saveWeapon = (id, payload) => {
  return {
    type: weaponConstants.WEAPON_SAVE,
    id,
    payload
  }
}

export const deleteWeapon = (id) => {
  return {
    type: weaponConstants.WEAPON_DELETE,
    id
  }
}

export const resetWeapon = () => {
  return {
    type: weaponConstants.WEAPON_RESET
  }
}
