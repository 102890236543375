export const uiConstants = {
  resetPassword: {
    buttonLabel: 'Reset Password',
    switchLabel: 'Forgot Password?',
    fields: [
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true
      }
    ]
  },
  login: {
    buttonLabel: 'Login',
    switchLabel: 'Login',
    fields: [
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true
      }
    ]
  },
  signup: {
    buttonLabel: 'Sign Up',
    switchLabel: 'Sign Up',
    fields: [
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
        required: true
      },
      {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true
      }
    ]
  }
}
