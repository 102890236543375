import { adminConstants } from '../constants'

const initialState = {
  users: null,
  activities: null,
  requests: null,
  loading: false,
  error: null
}

export default function user(state = initialState, action) {
  switch (action.type) {
    // case adminConstants.ADMIN_LOAD_USER:
    case adminConstants.ADMIN_ASSIGN_BADGE:
    case adminConstants.ADMIN_REMOVE_BADGE:
    case adminConstants.ADMIN_SAVE_USER:
    case adminConstants.ADMIN_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case adminConstants.ADMIN_USER_UPDATE:
      return {
        ...state,
        users:
          state.users === null
            ? [action.payload]
            : state.users.findIndex((x) => x.id === action.payload.id) === -1
            ? state.users.concat(action.payload)
            : state.users.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              })
      }
    case adminConstants.ADMIN_USER_REMOVE:
      return {
        ...state,
        users: state.users.filter((item) => {
          return item.id !== action.payload.id
        })
      }
    case adminConstants.ADMIN_REQUESTS_UPDATE:
      return {
        ...state,
        requests:
          state.requests === null
            ? [action.payload]
            : state.requests.findIndex((x) => x.id === action.payload.id) === -1
            ? state.requests.concat(action.payload)
            : state.requests.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              })
      }
    case adminConstants.ADMIN_REQUESTS_REMOVE:
      return {
        ...state,
        requests: state.requests.filter((x) => x.tag !== action.payload.tag)
      }
    case adminConstants.ADMIN_SAVE_USER_SUCCESS:
    case adminConstants.ADMIN_REMOVE_REQUEST_SUCCESS:
    case adminConstants.ADMIN_ASSIGN_BADGE_SUCCESS:
    case adminConstants.ADMIN_REMOVE_BADGE_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case adminConstants.ADMIN_ASSIGN_BADGE_FAILURE:
    case adminConstants.ADMIN_REMOVE_BADGE_FAILURE:
    case adminConstants.ADMIN_SAVE_USER_FAILURE:
    case adminConstants.ADMIN_REMOVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case adminConstants.ADMIN_ACTIVITIES_UPDATE:
      return {
        ...state,
        activities: (state.activities === null
          ? [action.payload]
          : state.activities.findIndex((x) => x.id === action.payload.id) === -1
          ? state.activities.concat(action.payload)
          : state.activities.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        ).sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0))
      }
    case adminConstants.ADMIN_ACTIVITIES_REMOVE:
      return {
        ...state,
        activities: state.activities.filter((item) => {
          return item.id !== action.payload.id
        })
      }
    case adminConstants.ADMIN_USER_ACTIVITY_INIT:
      return {
        ...state,
        activities: null
      }
    default:
      return state
  }
}
