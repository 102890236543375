export const categoryConstants = {
  homedefense: {
    value: 'homedefense',
    label: 'Home Defense',
    subcategories: {
      combatshootingshort: {
        label: 'corsi combat shooting arma corta',
        value: 'combatshooting-short'
      },
      combatshootinglong: {
        label: 'corsi combat shooting arma lunga',
        value: 'combatshooting-long'
      },
      homedefense: { label: 'corsi home defense', value: 'homedefense' },
      special: { label: 'corsi speciali', value: 'special' },
      team: { label: 'riconoscimenti speciali', value: 'team' }
    },
    contacts: [
      {
        href: 'https://homedefense.it',
        target: '_blank',
        icon: 'fas fa-globe',
        color: 'Web'
      },
      {
        href: 'tel:+393337248456',
        target: '',
        icon: 'fas fa-phone',
        color: 'Phone'
      },
      {
        href: 'mailto:info@homedefense.it',
        target: '_blank',
        icon: 'far fa-envelope',
        color: 'Email'
      },
      {
        href: 'https://wa.me/+393337248456',
        target: '_blank',
        icon: 'fab fa-whatsapp',
        color: 'Whatsapp'
      },
      {
        href: 'https://www.facebook.com/homedefense.it/',
        target: '_blank',
        icon: 'fab fa-facebook-f',
        color: 'Facebook'
      },
      {
        href: 'https://www.twitter.com/homedefenseit',
        target: '_blank',
        icon: 'fab fa-twitter',
        color: 'Twitter'
      },
      {
        href: 'https://www.instagram.com/homedefense.it',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  },
  tacticlub: {
    value: 'tacticlub',
    label: 'Tacti Club',
    subcategories: {
      club: { label: 'club', value: 'club' },
      special: { label: 'special members', value: 'special' }
    },
    contacts: [
      {
        href: 'mailto:info@tacti.club',
        target: '_blank',
        icon: 'far fa-envelope',
        color: 'Email'
      },
      {
        href: 'https://www.instagram.com/tacti.club',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  },
  defendo: {
    value: 'defendo',
    label: 'Defendo Krav Maga',
    subcategories: {
      belts: { label: 'cinture', value: 'belts' },
      special: { label: 'riconoscimenti speciali', value: 'special' }
    },
    contacts: [
      {
        href: 'https://defendokravmaga.it/',
        target: '_blank',
        icon: 'fas fa-globe',
        color: 'Web'
      },
      {
        href: 'mailto:info@defendokravmaga.it',
        target: '_blank',
        icon: 'far fa-envelope',
        color: 'Email'
      },
      {
        href: 'https://www.facebook.com/defendokravmaga',
        target: '_blank',
        icon: 'fab fa-facebook-f',
        color: 'Facebook'
      },
      {
        href: 'https://www.twitter.com/defendokravmaga',
        target: '_blank',
        icon: 'fab fa-twitter',
        color: 'Twitter'
      },
      {
        href: 'https://www.instagram.com/defendo.kravmaga',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  },
  casabella: {
    value: 'casabella',
    label: 'Armeria Casabella',
    subcategories: {
      clothing: { label: 'vestiario', value: 'clothing' },
      accessories: { label: 'accessori', value: 'accessories' },
      longweapons: { label: 'arma lunga', value: 'longweapons' },
      shortweapons: { label: 'arma corta', value: 'shortweapons' },
      optics: { label: 'ottiche', value: 'optics' },
      security: { label: 'sicurezza', value: 'security' },
      reload: { label: 'ricarica', value: 'reload' },
      freesale: { label: 'libera vendita', value: 'freesale' }
    },
    contacts: [
      {
        href: 'https://armeriacasabella.it/',
        target: '_blank',
        icon: 'fas fa-globe',
        color: 'Web'
      },
      {
        href: 'tel:+390399205318',
        target: '',
        icon: 'fas fa-phone',
        color: 'Phone'
      },
      {
        href: 'mailto:info@armeriacasabella.it',
        target: '_blank',
        icon: 'far fa-envelope',
        color: 'Email'
      },
      {
        href: 'https://www.facebook.com/armeriacasabella',
        target: '_blank',
        icon: 'fab fa-facebook-f',
        color: 'Facebook'
      },
      {
        href: 'https://www.instagram.com/armeria_casabella_1952',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  },
  girlsdefense: {
    value: 'girlsdefense',
    label: 'Girls Defense',
    subcategories: {
      selfdefense: { label: 'difesa personale', value: 'courses' },
      team: { label: 'riconoscimenti speciali', value: 'team' }
    },
    contacts: [
      {
        href: 'https://girlsdefense.it',
        target: '_blank',
        icon: 'fas fa-globe',
        color: 'Web'
      },
      {
        href: 'tel:+393297634316',
        target: '',
        icon: 'fas fa-phone',
        color: 'Phone'
      },
      {
        href: 'info@girlsdefense.it',
        target: '_blank',
        icon: 'far fa-envelope',
        color: 'Email'
      },
      {
        href: 'https://wa.me/393297634316',
        target: '_blank',
        icon: 'fab fa-whatsapp',
        color: 'Whatsapp'
      },
      {
        href: 'https://www.instagram.com/girlsdefense.it',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  },
  killhouse: {
    value: 'killhouse',
    label: 'Kill House',
    subcategories: {
      club: { label: 'club', value: 'club' },
      events: { label: 'eventi', value: 'events' },
      self_defense: { label: 'difesa personale', value: 'self-defense' },
      shooting: { label: 'shooting', value: 'shooting' }
    },
    contacts: [
      {
        href: 'https://killhouse.it/',
        target: '_blank',
        icon: 'fas fa-globe',
        color: 'Web'
      },
      {
        href: 'https://www.instagram.com/killhouse.it',
        target: '_blank',
        icon: 'fab fa-instagram',
        color: 'Instagram'
      }
    ]
  }
}
