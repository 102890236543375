import React from 'react'
import { connect } from 'react-redux'

import PageLoader from '../../../UI/PageLoader/PageLoader'

const ReduxLoader = (props) => {
  return props.auth.loading || props.user.loading || props.admin.loading ? (
    <PageLoader />
  ) : null
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(ReduxLoader)
