import React from 'react'

import { useBarcode } from 'react-barcodes'

const Barcode = ({ value }) => {
  const { inputRef } = useBarcode({
    value,
    options: {
      displayValue: false
    }
  })

  return <svg ref={inputRef} />
}

export default Barcode
