export const productConstants = {
  PRODUCT_INIT: '[Product] init',
  PRODUCT_UPDATE: '[Product] Update',
  PRODUCT_REMOVE: '[Product] Remove',
  PRODUCT_SAVE: '[Product] Save',
  PRODUCT_DELETE: '[Product] Delete',
  PRODUCT_BUY: '[Product] Buy',
  PRODUCT_BUY_SUCCESS: '[Product] Buy success',
  PRODUCT_BUY_FAILURE: '[Product] Buy failure',
  PRODUCT_CANCEL_BUY: '[Product] Cancel buy',
  PRODUCT_CANCEL_BUY_SUCCESS: '[Product] Cancel buy success',
  PRODUCT_CANCEL_BUY_FAILURE: '[Product] Cancel buy failure',
  PRODUCT_RESET: '[Product] Reset'
}
