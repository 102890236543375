export const authConstants = {
  AUTH_SIGNUP_EMAIL_PASSWORD: '[Auth] Signup Email Password',
  AUTH_RECOVER_PASSWORD: '[Auth] Recover Password',
  AUTH_RECOVER_PASSWORD_SUCCESS: '[Auth] Recover Password success',
  AUTH_RECOVER_PASSWORD_FAILURE: '[Auth] Recover Password failure',
  AUTH_LOGIN_EMAIL_PASSWORD: '[Auth] Login Email Password',
  AUTH_LOGIN_GOOGLE: '[Auth] Login Google',
  AUTH_LOGIN_FACEBOOK: '[Auth] Login Facebook',
  AUTH_LOGIN_TWITTER: '[Auth] Login Twitter',
  AUTH_LOGIN_CHECK_USER: '[Auth] Check User',
  AUTH_LOGIN_CREATE_USER: '[Auth] Create User',
  AUTH_LOGIN_SUCCESS: '[Auth] Login success',
  AUTH_LOGIN_FAILURE: '[Auth] Login error',
  AUTH_LOGOUT: '[Auth] Logout',
  AUTH_LOGOUT_SUCCESS: '[Auth] Logout success',
  AUTH_LOGOUT_FAILURE: '[Auth] Logout failure',
  AUTH_VALIDATE: '[Auth] Validate',
  AUTH_VALIDATE_FAILURE: '[Auth] Validate failure',
  AUTH_VALIDATE_SUCCESS: '[Auth] Validate success',
  AUTH_CHECK_USER_ALREADY_LOGGED: '[Auth] Check user is already logged'
}
