export const userConstants = {
  settings: [
    {
      label: 'Provider',
      name: 'providerId',
      placeholder: 'providerId',
      type: 'providerId',
      required: true,
      disabled: true
    },
    {
      label: 'Nome Cognome',
      name: 'displayName',
      placeholder: 'Nome Cognome',
      type: 'text',
      required: true
    },
    {
      label: 'Email',
      name: 'email',
      placeholder: 'Email',
      type: 'email',
      required: true
    },
    {
      label: 'Telefono',
      name: 'phoneNumber',
      placeholder: 'Telefono',
      type: 'tel',
      required: false
    }
  ]
}
