import { requestConstants } from '../constants'

export const initRequest = (payload) => {
  return {
    type: requestConstants.REQUEST_INIT,
    payload
  }
}

export const updateRequest = (payload) => {
  return {
    type: requestConstants.REQUEST_UPDATE,
    payload
  }
}

export const removeRequest = (payload) => {
  return {
    type: requestConstants.REQUEST_REMOVE,
    payload
  }
}

export const makeRequest = (payload) => {
  return {
    type: requestConstants.REQUEST_MAKE,
    payload
  }
}

export const makeRequestStarts = () => {
  return {
    type: requestConstants.REQUEST_MAKE_STARTS
  }
}

export const makeRequestEnds = () => {
  return {
    type: requestConstants.REQUEST_MAKE_ENDS
  }
}

export const resetRequest = () => {
  return {
    type: requestConstants.REQUEST_RESET
  }
}
