import React from 'react'

import css from './Footer.module.scss'
import Logo from '../../../../assets/logo.svg'
import Privacy from '../../../UI/Privacy/Privacy'

const Footer = () => (
  <footer className={css.Footer}>
    <img src={Logo} alt="Home Defense Academy" />
    &copy;{new Date().getFullYear()} - v.{' '}
    {document.head.querySelector('meta[name="build-version"]')['content']}
    <Privacy />
  </footer>
)

export default Footer
