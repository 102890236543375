import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { validateAuth } from '../../../../redux/actions'

const CheckAuth = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(validateAuth())
  }, [dispatch])

  return <React.Fragment />
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(CheckAuth)
