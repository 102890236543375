export const weaponConstants = {
  types: [
    {
      label: 'arma',
      value: 'arma',
      plural: 'armi'
    },
    {
      label: "porto d'armi",
      value: 'pda',
      plural: "porto d'armi"
    },
    {
      label: 'tessera',
      value: 'tessera',
      plural: 'tessere'
    }
  ],
  categories: [
    {
      label: 'comune',
      value: 'comune'
    },
    {
      label: 'sportiva',
      value: 'sportiva'
    },
    {
      label: 'caccia',
      value: 'caccia'
    }
  ],
  calibers: [
    '22 LR',
    '7 Penna',
    '7.65 Browning',
    '7.65 Parabellum',
    '.357 Magnum',
    '.38',
    '380 Auto',
    '9x21',
    '9x19',
    '.40 S&W',
    '.44 Magnum',
    '6.5',
    '7.5x55',
    '7.62x54R',
    '8x57 JS',
    '.30-06 Springfield',
    '.308 Winchester',
    '12',
    '.338',
    '.500 S&W',
    '5.45x39',
    '20',
    '7.62x39',
    '.223 Remington',
    '.300 Winchester Magnum',
    '.50 BMG'
  ],
  licenses: [
    {
      label: 'porto di fucile uso tiro a volo',
      value: 'sport'
    },
    {
      label: 'porto di fucile uso caccia',
      value: 'hunt'
    },
    {
      label: "porto d'armi per difesa personale",
      value: 'defense'
    }
  ],
  labels: {
    model: 'modello',
    brand: 'produttore',
    serialNumber: 'matricola',
    caliber: 'calibro',
    category: 'categoria',
    releaseDate: 'data di rilascio',
    expiryDate: 'data di scadenza',
    number: 'numero',
    place: 'luogo',
    releasedBy: 'rilasciato da',
    buyDate: 'data di acquisto'
  }
}
