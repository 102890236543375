import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { requestsRef, usersRef } from '../../database'
import emailjs from 'emailjs-com'
import { config } from '../../config'

import {
  updateRequest,
  removeRequest,
  addNotification,
  makeRequestStarts,
  makeRequestEnds
} from '../actions'
import { appearanceConstants } from '../../constants'
import {
  query,
  where,
  addDoc,
  doc,
  getDoc,
  onSnapshot
} from 'firebase/firestore'

export let requestsSnap

export function* initRequestSaga(action) {
  try {
    const listener = eventChannel((emit) => {
      const q = query(requestsRef, where('uid', '==', action.payload))

      requestsSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => requestsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateRequest({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateRequest({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeRequest({ id, ...data }))
      }
    }
  } catch (error) {
    // TODO: must do something
    console.log(error)
  }
}

export function* makeRequestSaga(action) {
  try {
    const userid = action.payload.userid
    const uid = action.payload.useruid

    yield put(makeRequestStarts())

    let user = yield getDoc(doc(usersRef, userid))
    user = user.data()

    yield addDoc(requestsRef, {
      tag: action.payload.tag,
      uid
    })
    yield emailjs.send(
      config.badgeRequest.serviceid,
      config.badgeRequest.templateId,
      {
        user: user.displayName
      },
      config.badgeRequest.userid
    )

    yield put(makeRequestEnds())
    yield put(addNotification(appearanceConstants.messages.badgerequestsuccess))
  } catch (error) {
    console.log(error)
    yield put(makeRequestEnds())
    yield put(addNotification(appearanceConstants.messages.badgerequestfailure))
  }
}
