import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { badgesRef, database } from '../../database'

import { updateBadge, removeBadge, addNotification, redirect } from '../actions'
import { appearanceConstants, firestoreConstants } from '../../constants'
import {
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc
} from 'firebase/firestore'

export let badgesSnap

export function* initBadgeSaga() {
  try {
    const listener = eventChannel((emit) => {
      badgesSnap = onSnapshot(badgesRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => badgesSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (change.type === 'added') {
        yield put(updateBadge({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateBadge({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeBadge({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(addNotification('Error loading badges', true))
  }
}

export function* saveBadgeSaga({ id, payload }) {
  try {
    if (!id) {
      const docRef = yield addDoc(badgesRef, payload)
      yield put(redirect(`/admin/badges/${docRef.id}`))

      yield put(addNotification(appearanceConstants.messages.savesuccess))
    } else {
      yield updateDoc(doc(database, firestoreConstants.badges, id), payload)

      yield put(addNotification(appearanceConstants.messages.savesuccess))
    }
  } catch (error) {
    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}

export function* deleteBadgeSaga(payload) {
  try {
    yield deleteDoc(doc(database, firestoreConstants.badges, payload.id))
    yield put(redirect(`/admin/badges`))

    yield put(addNotification(appearanceConstants.messages.deletesuccess))
  } catch (error) {
    yield put(addNotification(appearanceConstants.messages.savefailure, true))
  }
}
