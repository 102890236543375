import { adminConstants } from '../constants'

export const initAdminRequests = () => {
  return {
    type: adminConstants.ADMIN_REQUESTS_INIT
  }
}

export const adminRequestUpdate = (payload) => {
  return {
    type: adminConstants.ADMIN_REQUESTS_UPDATE,
    payload
  }
}

export const adminRequestRemove = (payload) => {
  return {
    type: adminConstants.ADMIN_REQUESTS_REMOVE,
    payload
  }
}

export const adminAssignBadge = (payload) => {
  return {
    type: adminConstants.ADMIN_ASSIGN_BADGE,
    payload
  }
}

export const adminAssignBadgeSuccess = (payload) => {
  return {
    type: adminConstants.ADMIN_ASSIGN_BADGE_SUCCESS,
    payload
  }
}

export const adminAssignBadgeFailure = (payload) => {
  return {
    type: adminConstants.ADMIN_ASSIGN_BADGE_FAILURE,
    payload
  }
}

export const adminRemoveBadge = (payload) => {
  return {
    type: adminConstants.ADMIN_REMOVE_BADGE,
    payload
  }
}

export const adminRemoveBadgeSuccess = (payload) => {
  return {
    type: adminConstants.ADMIN_REMOVE_BADGE_SUCCESS,
    payload
  }
}
export const adminRemoveBadgeFailure = (payload) => {
  return {
    type: adminConstants.ADMIN_REMOVE_BADGE_FAILURE,
    payload
  }
}

export const adminSaveUser = (payload) => {
  return {
    type: adminConstants.ADMIN_SAVE_USER,
    payload
  }
}

export const adminSaveUserSuccess = (payload) => {
  return {
    type: adminConstants.ADMIN_SAVE_USER_SUCCESS,
    payload
  }
}
export const adminSaveUserFailure = (payload) => {
  return {
    type: adminConstants.ADMIN_SAVE_USER_FAILURE,
    payload
  }
}

export const initAdminUser = () => {
  return {
    type: adminConstants.ADMIN_USERS_INIT
  }
}

export const adminUpdateUser = (payload) => {
  return {
    type: adminConstants.ADMIN_USER_UPDATE,
    payload
  }
}

export const adminRemoveUser = (payload) => {
  return {
    type: adminConstants.ADMIN_USER_REMOVE,
    payload
  }
}

export const adminRemoveRequest = (payload) => {
  return {
    type: adminConstants.ADMIN_REMOVE_REQUEST,
    payload
  }
}

export const adminRemoveRequestSuccess = (payload) => {
  return {
    type: adminConstants.ADMIN_REMOVE_REQUEST_SUCCESS,
    payload
  }
}

export const adminRemoveRequestFailure = () => {
  return {
    type: adminConstants.ADMIN_REMOVE_REQUEST_FAILURE
  }
}

export const adminInitUserActivity = (payload) => {
  return {
    type: adminConstants.ADMIN_USER_ACTIVITY_INIT,
    payload
  }
}

export const adminUpdateUserActivity = (payload) => {
  return {
    type: adminConstants.ADMIN_ACTIVITIES_UPDATE,
    payload
  }
}

export const adminRemoveUserActivity = (payload) => {
  return {
    type: adminConstants.ADMIN_ACTIVITIES_REMOVE,
    payload
  }
}
